<script>
import Fixtures from "@/services/Fixtures";
import Http from "@/services/Http";
import Swal from "sweetalert2";
export default {
  props: {
    eventTypeId: Number, //
    eventName: String,
    eventMinute: String,
    eventPlayer: String,
    eventSecondPlayer: String,
    home_away: String,
    eventReason: String,
    match_id: String,
    eventPlayerId: Number,
    eventReasonId: Number,
    eventHomeGoals: Number,
    eventAwayGoals: Number,
  },
  data() {
    return {
      eventType: this.eventTypeId > 4 && this.eventTypeId<=11,
    };
  },
  computed: {
    field() {
      return this.eventType ? this.eventPlayer : this.eventName;
    },
    ownGoalFill() {
      return this.eventReasonId === 8 ? "red" : "";
    },
  },
  methods: {
    async addSportMatchVAREvent() {
      try {
        this.tryingToSubmit = true;
        const payload = {
          event: "VAR - Goal Disallowed",
          player_id: this.eventPlayerId,
          match_id: this.match_id,
          home_away: this.home_away == "home" ? 1 : -1,
          event_reason_name: "No goal",
          event_reason_id: 10,
        };
        await Fixtures.addSportMatchEvent(this.match_id, payload);
        this.successmsg();
        this.tryingToSubmit = false;
      } catch (error) {
        this.failedmsg(error.response.data.error);
        this.tryingToSubmit = false;
      }
    },
    successmsg() {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Change has been saved",
        showConfirmButton: false,
        timer: 2000,
      });
    },

    failedmsg(msg) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: msg,
        showConfirmButton: false,
        timer: 5000,
      });
    },
  },
};
</script>

<template>
  <div class="event-table">
    <div v-if="!eventType">{{ eventName }}</div>
    <div v-else>
      <p>{{ home_away === "home" ? field : "" }}</p>
      <p v-if="eventSecondPlayer && home_away === 'home'" class="text-sm">
        {{ eventSecondPlayer }}
      </p>
      <p v-if="eventReason && home_away==='home' && eventReason!=='Goal'">({{ eventReason}})</p>
      <p v-if="eventTypeId===5 && home_away==='home'">({{eventHomeGoals}}:{{eventAwayGoals}})</p>
    </div>
    <div class="d-flex flex-column align-items-center">
      <svg
        v-if="!eventType"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
      <div
        v-else-if="eventTypeId === 5"
        class="d-flex flex-column align-items-center"
      >
        <svg viewBox="0 0 20 20" :fill="ownGoalFill">
          <title>Own goal</title>
          <path
            fill-rule="evenodd"
            class="soccer"
            d="M17 2.93a9.96 9.96 0 1 0-14.08 14.1A9.96 9.96 0 0 0 17 2.92Zm.41 2.77a8.5 8.5 0 0 1 1.1 3.43L16.66 8.1l.75-2.4Zm-1.37-1.8.37.4-1.11 3.57-1.33.4-3.32-2.41V4.5l3.16-2.2a8.6 8.6 0 0 1 2.22 1.6ZM9.96 1.4c.78-.01 1.55.1 2.3.3l-2.3 1.6-2.3-1.6c.75-.2 1.52-.31 2.3-.3ZM3.9 3.9a8.6 8.6 0 0 1 2.22-1.6l3.16 2.2v1.36l-3.32 2.4-1.32-.4L3.52 4.3l.37-.4ZM2.52 5.7l.75 2.4-1.85 1.03a8.5 8.5 0 0 1 1.1-3.43Zm1.37 10.35-.22-.23H5.7l.65 1.95a8.6 8.6 0 0 1-2.45-1.72Zm2.01-1.6H2.63A8.5 8.5 0 0 1 1.4 10.7l2.75-1.55 1.41.43 1.28 3.91-.95.95Zm6.05 3.89c-1.3.3-2.66.3-3.97 0l-1.01-3.02 1.1-1.1h3.79l1.1 1.1-1.01 3.02Zm-.07-5.44H8.05L6.86 9.25 9.96 7l3.1 2.25-1.18 3.65Zm4.15 3.15a8.6 8.6 0 0 1-2.45 1.72l.66-1.94h2.01l-.22.22Zm-2-1.6-.95-.95 1.27-3.91 1.41-.43 2.76 1.55a8.5 8.5 0 0 1-1.22 3.74h-3.27Z"
          ></path>
        </svg>
      </div>
      <div v-else-if="eventTypeId === 6" class="yellow-card"></div>
      <div v-else-if="eventTypeId === 10">
        <img
          src="https://s3-eu-west-1.amazonaws.com/stadiaconnect-cdn/icons/substitution.png"
          alt=""
        />
      </div>
      <div v-else-if="eventTypeId === 11">
        <img alt="File:VAR System Logo.svg" src="//upload.wikimedia.org/wikipedia/commons/thumb/6/6f/VAR_System_Logo.svg/429px-VAR_System_Logo.svg.png" decoding="async" srcset="//upload.wikimedia.org/wikipedia/commons/thumb/6/6f/VAR_System_Logo.svg/644px-VAR_System_Logo.svg.png 1.5x, //upload.wikimedia.org/wikipedia/commons/thumb/6/6f/VAR_System_Logo.svg/858px-VAR_System_Logo.svg.png 2x" data-file-width="429" data-file-height="281">
      </div>
      <div v-else-if="eventTypeId === 8" class="yellow-red-card">
    </div>
      <div v-else class="red-card"></div>
      <p>{{ eventMinute }}</p>
    </div>
    <p v-if="!eventType">{{ eventName }}</p>
    <div v-else>
      <p>{{ home_away === "away" ? field : "" }}</p>
      <p v-if="eventSecondPlayer && home_away === 'away'" class="text-sm">
        {{ eventSecondPlayer }}
      </p>
      <p v-if="eventReason && home_away==='away' && eventReason!=='Goal'">({{ eventReason}})</p>
      <p v-if="eventTypeId===5 && home_away === 'away'">({{eventHomeGoals}}:{{eventAwayGoals}})</p>
      
    </div>
  </div>
</template>

<style>
p {
  margin: 0;
}
svg {
  width: 20px;
}
img {
  width: 35px;
}

.event-table {
  padding-block: 0.5rem;
  display: grid;
  grid-template-columns: repeat(3, 220px);
  gap: 1rem;
  place-content: center;
  place-items: center;
}

.event-table + .event-table {
  margin-top: 0.75rem;
}

.yellow-card {
  width: 14px;
  height: 18px;
  background-color: #ffe500;
  border-radius: 0.125rem;
  transform: rotate(15deg);
  margin-bottom: 0.25rem;
}
.red-card {
  width: 14px;
  height: 18px;
  background-color: rgb(219, 7, 0);
  border-radius: 0.125rem;
  transform: rotate(15deg);
  margin-bottom: 0.25rem;
}
.text-sm {
  font-size: 0.75rem;
}
.yellow-red-card {
  position: relative;
  width: 14px;
  height: 18px;
  background-color: #ffe500; /* Yellow */
  border-radius: 0.125rem;
  transform: rotate(15deg);
  margin-bottom: 0.25rem;
  overflow: hidden;
}

.yellow-red-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ff0000; /* Red */
  clip-path: polygon(0 0, 100% 0, 0 100%);
}
</style>
