<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import Fixtures from "../../../services/Fixtures";
import modalScoreGoal from "../../../components/modals/simulator_new/modalScoreGoal.vue";
import modalYellowCard from "../../../components/modals/simulator_new/modalYellowCard.vue";
import modalRedCard from "../../../components/modals/simulator_new/modalRedCard.vue";
import modalSubstitution from "../../../components/modals/simulator_new/modalSubstitution.vue";
import modalMOTM from "../../../components/modals/simulator_new/modalMOTM.vue";
import modalChangeStatus from "../../../components/modals/simulator_new/modalChangeStatus.vue";
import modalEditRoster from "../../../components/modals/simulator_new/modalEditRoster.vue";
import Events from "./components_new/Events.vue";

export default {
  components: {
    Layout,
    PageHeader,
    Events,
    modalScoreGoal,
    modalYellowCard,
    modalRedCard,
    modalSubstitution,
    modalMOTM,
    modalChangeStatus,
    modalEditRoster
  },
  page: {
    title: "Match Simulator",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Match Simulator New",
      items: [
        {
          text: "Matches",
        },
        {
          text: "Match Simulator",
        },
      ],
      eventTeam: 0, // 0 for home, 1 for away
      match_id: this.$route.params.id,
      match: null,
      canEdit: false,
      timer: { minutes: 0, seconds: 0 },
      homeSubs: [],
      awaySubs: [],
      periods: [],
      events: [],
      match_period: 0,
      match_status: null,
      period: 0,
      homeTeam: {
        home_team_id: null,
        home_team_name: "",
        home_team_logo: "",
        score: 0,
        roster: [],
        home_formation: ""
      },
      awayTeam: {
        away_team_id: null,
        away_team_name: "",
        away_team_logo: "",
        score: 0,
        roster: [],
        away_formation: ""

      },
      rosterCreated: true,
      isBusy: false,
      error: null,
      showModal: false,
      modalData: {},
      refresh: false,
      timerStarted: false,
      selectedNextPeriod: null,
      showSelect: false,
      nextPeriodOptions: [],
    };
  },
  created() {
    this.getSportMatch();
    this.getSportMatchLineups();
    this.getSportMatchEvents();
    this.getSportMatchPeriods();
    if (this.canEdit === false) this.startTimer();
  },

  methods: {
    async getSportMatchPeriods() {
      try {
        const response = await Fixtures.getSportMatchPeriods();
        this.periods = response.data.periods;
      } catch (error) {
        console.error("Error fetching match periods:", error);
      }
    },
    selectNextPeriod(period) {
      this.selectedNextPeriod = period;
      this.changeSportMatchStatus();
      this.showSelect = false;
    },
    isMatchActive() {
      return (
        this.match &&
        (this.match.match_status === "Playing" ||
          this.match.match_status === "Fixture")
      );
    },
    isMatchTie() {
      return this.match && this.match.score_home === this.match.score_away;
    },
    getNextPeriodName() {
      const logicalOrder = [
        "Pre-match",
        "First half",
        "Half time",
        "Second half",
        "End of second half - before extra time",
        "Extra time - first half",
        "Extra time - half time",
        "Extra time - second half",
        "End of extra time - before penalties",
        "Penalty shootout",
        "Full time",
      ];

      const currentPeriodIndex = logicalOrder.findIndex((period) => {
        return (
          this.periods.find((p) => p.period_name === period).period_id ===
          this.match_period
        );
      });

      if (currentPeriodIndex === -1) {
        return "Unknown Period";
      }

      if (logicalOrder[currentPeriodIndex] === "Second half") {
        if (this.isMatchTie()) {
          this.showSelect = true;
          this.nextPeriodOptions = this.periods.filter(
            (p) =>
              p.period_name === "Full time" ||
              p.period_name === "End of second half - before extra time"
          );
          return "Choose Next Period";
        } else {
          this.showSelect = false;
          return "Full time";
        }
      }

      if (logicalOrder[currentPeriodIndex] === "Extra time - second half") {
        if (this.isMatchTie()) {
          this.showSelect = true;
          this.nextPeriodOptions = this.periods.filter(
            (p) =>
              p.period_name === "Full time" ||
              p.period_name === "End of extra time - before penalties"
          );
          return "Choose Next Period";
        } else {
          this.showSelect = false;
          return "Full time";
        }
      }

      let nextPeriodIndex = currentPeriodIndex + 1;
      if (nextPeriodIndex >= logicalOrder.length) {
        nextPeriodIndex = logicalOrder.indexOf("Full time");
      }

      this.showSelect = false;
      const nextPeriodName = logicalOrder[nextPeriodIndex];
      return nextPeriodName;
    },
    async addSportMatchVAREvent() {
      try {
        this.tryingToSubmit = true;
        const lastGoalEvent = this.events ? this.events[0] : null;
        if (lastGoalEvent) {
          const payload = {
            event: "VAR - Goal Disallowed",
            player_id: lastGoalEvent.player_id,
            match_id: this.match_id,
            home_away: lastGoalEvent.place == "home" ? 1 : -1,
            event_reason_name: "No goal",
            event_reason_id: 10,
          };

          await Fixtures.addSportMatchEvent(this.match_id, payload);
          this.successmsg();
          await this.getSportMatchEvents();
          await this.getSportMatch();
          this.tryingToSubmit = false;
        }
      } catch (error) {
        this.failedmsg(error.response.data.error);
        this.tryingToSubmit = false;
      }
    },
    async getSportMatch() {
      try {
        const response = await Fixtures.getSportMatch(this.match_id);
        this.homeTeam.home_team_name = response.data.match.home_team_name;
        this.homeTeam.home_team_id = response.data.match.home_team_id;
        this.homeTeam.home_team_logo = response.data.match.home_team_logo;
        this.homeTeam.home_formation = response.data.match.home_formation;
        this.awayTeam.away_team_name = response.data.match.away_team_name;
        this.awayTeam.away_team_id = response.data.match.away_team_id;
        this.awayTeam.away_team_logo = response.data.match.away_team_logo;
        this.awayTeam.away_formation = response.data.match.away_formation;
        this.homeTeam.score = response.data.match.score_home || 0;
        this.awayTeam.score = response.data.match.score_away || 0;

        this.match = response.data.match;
        this.match_period = response.data.match.match_period_id;
        this.match_status = this.match.match_status;

        if (this.match_period === 16) {
          this.timer.minutes = 0;
          this.timer.seconds = 0;
          this.timerStarted = false;
          return;
        }

        let periodStart, currentTime, elapsedTime;

        switch (this.match_period) {
          case 1: // First half
            periodStart = new Date(
              response.data.match.period[0].period_start * 1000
            );
            currentTime = new Date();
            elapsedTime = currentTime - periodStart;
            this.timer.minutes = Math.floor(elapsedTime / 60000);
            this.timer.seconds = Math.floor((elapsedTime % 60000) / 1000);
            this.timerStarted = true;
            break;

          case 10: // Halftime
            this.timer.minutes = 45;
            this.timer.seconds = 0;
            // this.timerStarted = false;
            break;

          case 2: // Second half
            periodStart = new Date(
              response.data.match.period[1].period_start * 1000
            );
            currentTime = new Date();
            elapsedTime = currentTime - periodStart;
            this.timer.minutes = 45 + Math.floor(elapsedTime / 60000);
            this.timer.seconds = Math.floor((elapsedTime % 60000) / 1000);
            this.timerStarted = true;
            break;

          case 14: // Fulltime
            // this.timer.minutes = 90;
            // this.timer.seconds = 0;
            this.timerStarted = false;
            break;

          case 3: // Extra time - first half
            periodStart = new Date(
              response.data.match.period[2].period_start * 1000
            );
            currentTime = new Date();
            elapsedTime = currentTime - periodStart;
            this.timer.minutes = 90 + Math.floor(elapsedTime / 60000);
            this.timer.seconds = Math.floor((elapsedTime % 60000) / 1000);
            this.timerStarted = true;
            break;

          case 12: // Extra time - halftime
            this.timer.minutes = 105;
            this.timer.seconds = 0;
            this.timerStarted = false;
            break;
          case 11:
            this.timerStarted = false;
            break;
          case 4: // Extra time - second half
            periodStart = new Date(
              response.data.match.period[3].period_start * 1000
            );
            currentTime = new Date();
            elapsedTime = currentTime - periodStart;
            this.timer.minutes = 105 + Math.floor(elapsedTime / 60000);
            this.timer.seconds = Math.floor((elapsedTime % 60000) / 1000);
            this.timerStarted = true;
            break;
        }
      } catch (error) {
        this.failedmsg(error.response.data);
      }
    },

    async createSportRoster() {
      try {
        this.isBusy = true;
        await Fixtures.createSportRoster(this.match_id);
        this.rosterCreated = true;
        this.isBusy = false;
        this.getSportMatchLineups();
      } catch (error) {
        this.failedmsg(error.response.data.error);
        this.isBusy = false;
      }
    },

    async getSportMatchLineups() {
      try {
        const response = await Fixtures.getSportMatchLineups(this.match_id);
        const substitutionEvents = this.events.filter(
          (ev) => ev.event_type_name === "Substitution"
        );

        const substitutedOutPlayerIds = new Set(
          substitutionEvents.map((ev) => ev.related_player_id)
        );
        const substitutedInPlayerIds = new Set(
          substitutionEvents.map((ev) => ev.player_id)
        );

        console.log("Response data:", response.data);
        console.log("Substituted Out Player IDs:", substitutedOutPlayerIds);
        console.log("Substituted In Player IDs:", substitutedInPlayerIds);

        if (
          response.data.lineups.home_team_starters.length === 0 &&
          response.data.lineups.away_team_starters.length === 0
        ) {
          this.rosterCreated = false;
          return;
        }

        this.homeTeam.roster = [
          ...response.data.lineups.home_team_starters.filter(
            (e) => !substitutedOutPlayerIds.has(e.player_id)
          ),
          ...response.data.lineups.home_team_subs.filter((e) =>
            substitutedInPlayerIds.has(e.player_id)
          ),
        ];

        this.awayTeam.roster = [
          ...response.data.lineups.away_team_starters.filter(
            (e) => !substitutedOutPlayerIds.has(e.player_id)
          ),
          ...response.data.lineups.away_team_subs.filter((e) =>
            substitutedInPlayerIds.has(e.player_id)
          ),
        ];

        this.homeSubs = [
          ...response.data.lineups.home_team_subs.filter(
            (player) => !substitutedInPlayerIds.has(player.player_id)
          ),
          ...response.data.lineups.home_team_starters.filter((player) =>
            substitutedOutPlayerIds.has(player.player_id)
          ),
        ];

        this.awaySubs = [
          ...response.data.lineups.away_team_subs.filter(
            (player) => !substitutedInPlayerIds.has(player.player_id)
          ),
          ...response.data.lineups.away_team_starters.filter((player) =>
            substitutedOutPlayerIds.has(player.player_id)
          ),
        ];
        console.log(this.homeTeam.roster)

        this.rosterCreated = true;
      } catch (error) {
        this.failedmsg(error.response.data.message);
      }
    },

    async getSportMatchEvents() {
      try {
        const response = await Fixtures.getSportMatchEvents(this.match_id);
        this.events = response.data.events;
        this.refresh = !this.refresh;
      } catch (error) {
        if (error.response.data.error === "No match events") return;
        this.failedmsg(error.response.data.error);
      }
    },

    async changeSportMatchStatus() {
      try {
        if (this.showSelect && this.selectedNextPeriod) {
          console.log("here");
          const next_period = this.selectedNextPeriod;
          const payload = {
            match_id: this.match_id,
            next_period: next_period,
            next_status: next_period === "Full time" ? "Played" : "Playing",
          };
          await Fixtures.changeSportMatchStatus(this.match_id, payload);
          this.successmsg(`Match status changed to ${next_period}`);
        } else if (!this.showSelect) {
          await Fixtures.changeSportMatchStatus(this.match_id);
        }
        this.getSportMatch();
        this.selectedNextPeriod = null;
      } catch (error) {
        console.error("Error changing match status:", error);
      }
    },

    handleResponse(teamId) {
      console.log("Received response from modalYellowCard or red:", teamId);
      this.getSportMatchLineups();
      this.getSportMatchEvents();
    },
    startTimer() {
      if (this.canEdit && this.isMatchActive() && this.timerStarted) {
        setTimeout(() => {
          if (this.timer.seconds > 58) {
            this.timer.minutes += 1;
            this.timer.seconds = -1;

            // if (this.timer.minutes === 45 || this.timer.minutes === 90 || this.timer.minutes === 105 || this.timer.minutes === 120) {
            //   this.timer.seconds = 0;
            //   this.changeSportMatchStatus();
            //   return;
            // }
          }
          this.timer.seconds++;
          this.startTimer();
        }, 1000);
      }
    },
    score() {
      this.getSportMatchEvents();
      this.getSportMatch();
    },

    modalScoreGoal(team) {
      this.eventTeam = team;
      this.$bvModal.show("score_goal");
    },

    modalYellowCard(team) {
      this.eventTeam = team;
      this.$bvModal.show("yellow_card");
    },

    modalRedCard(team) {
      this.eventTeam = team;
      this.$bvModal.show("red_card");
    },

    modalSubstitution(team) {
      this.eventTeam = team;
      this.$bvModal.show("substitution");
    },

    modalMOTM() {
      this.$bvModal.show("motm");
    },
    modalChangeStatus() {
      this.$bvModal.show("change_status");
    },
    modalEditRoster(team) {
      this.eventTeam = team;
      this.$bvModal.show("edit_roster");
    },
    statusUpdated() {
      this.getSportMatch();
    },
    failedmsg(msg) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Oops...",
        text: msg,
        showConfirmButton: false,
        timer: 3000,
      });
    },
  },
  watch: {
    canEdit(e) {
      e && this.startTimer();
    },
    match_period(s) {
      this.showSelect = false;
      this.startTimer();
      if (s === 16 || s === 10 || s === 14 || s===11 || s===12|| s===13) {
        this.canEdit = false;
      } else this.canEdit = true;
    },
    match_status(s) {
      // if (s === "Playing") {
      //   this.canEdit = true;
      // } else this.canEdit = false;
    },
  },
  mounted() {
    this.$root.$on("statusUpdated", this.statusUpdated);
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div
      class="d-flex gap-2 justify-content-center container bg-white mw-75 mb-4 py-2 shadow rounded"
    >
      <b-dropdown
        id="dropdown-1"
        v-if="showSelect"
        text="Choose Next Period"
        class="w-100 bg-primary rounded"
        @click="changeSportMatchStatus"
        variant="primary"
      >
        <b-dropdown-item
          v-for="period in nextPeriodOptions"
          :key="period.period_id"
          @click="selectNextPeriod(period.period_name)"
        >
          {{ period.period_name }}
        </b-dropdown-item>
      </b-dropdown>
      <button
        class="btn btn-success w-100"
        v-else
        @click="changeSportMatchStatus"
        :disabled="match_period === 14 || !rosterCreated || !isMatchActive()"
      >
        {{ getNextPeriodName() }}
      </button>
      <button
        class="btn btn-primary w-100"
        v-if="match_period === 14"
        @click="modalMOTM()"
      >
        {{ "Man Of The Match" }}
      </button>
      <div v-else class="w-100 d-flex justify-content-between">
        <button v-if=!rosterCreated
        class="btn btn-primary w-100"
        @click="createSportRoster"
        :disabled="rosterCreated || match_status !== 'Fixture'"
      >
        {{ isBusy ? "Creating..." : "Auto-Create Roster" }}
      </button>
      <div class="w-100 d-flex justify-content-between" v-else>
        <button
        class="btn btn-primary w-50 mr-2"
        @click="modalEditRoster(0)"
        :disabled="match_status !== 'Fixture'"
        
      >
        Edit Home Team
      </button>
      <button
        class="btn btn-primary w-50"
        @click="modalEditRoster(1)"
        :disabled="match_status !== 'Fixture'"
      >
      Edit Away Team
      </button>
      </div>
      </div>
      
    </div>
    <div
      class="container bg-white mw-75 d-flex justify-content-center rounded p-4 shadow"
    >
      <div class="d-flex align-items-baseline">
        <div class="text-center logo-cover">
          <img
            :src="homeTeam.home_team_logo"
            :alt="homeTeam.home_team_name"
            class="rounded-circle w-50"
          />
          <p>{{ homeTeam.home_team_name }}</p>
        </div>
        <div class="text-center d-flex flex-column align-items-center">
          <div class="d-flex align-items-center justify-content-center">
            <!-- <span class="mb-3 px-2">Status:</span> -->
            <div
              class="badge bg-pill bg-soft-success font-size-12 mb-3"
              :class="{
                'bg-soft-danger': match.match_status === 'Cancelled',
                'bg-soft-warning': match.match_status === 'Postponed',
                'bg-orange': match.match_status === 'Suspended',
                'bg-gray': match.match_status === 'Fixture',
                'bg-primary': match.match_status === 'Played',
              }"
            >
              <p>{{ match.match_status.toUpperCase() }}</p>
            </div>
            <i
              class="fas fa-pen ms-2 mb-3 font-size-13"
              v-if="
                match.match_status !== 'Cancelled' &&
                  match.match_status !== 'Played'
              "
              @click="modalChangeStatus()"
              style="cursor: pointer;"
            ></i>
          </div>
          <div class="d-flex gap-4 align-items-end">
            <h3>{{ homeTeam.score }}</h3>
            <h3>-</h3>
            <h3>{{ awayTeam.score }}</h3>
          </div>
          <p class="mb-1 d-flex">
            <span>
              {{
                timer.minutes.toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                })
              }}
            </span>
            :
            <span>
              {{
                timer.seconds.toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                })
              }}
            </span>
          </p>
          <p>{{ match.match_period_name }}</p>
        </div>
        <div class="text-center logo-cover">
          <img
            :src="awayTeam.away_team_logo"
            :alt="awayTeam.away_team_name"
            class="rounded-circle w-50"
          />
          <p>{{ awayTeam.away_team_name }}</p>
        </div>
      </div>
    </div>
    <div
      class="d-flex container bg-white shadow mw-75 justify-content-around mt-4 py-2 rounded gap-2"
    >
      <div class="w-100 px- d-flex flex-column gap-2">
        <button
          :disabled="!rosterCreated || !canEdit"
          id="show-btn"
          @click="modalScoreGoal(0)"
          class="btn-success btn w-100"
        >
          Score
        </button>
        <button
          :disabled="!rosterCreated || !canEdit"
          @click="modalYellowCard(0)"
          class="btn-warning btn w-100"
        >
          Yellow Card
        </button>
        <button
          :disabled="!rosterCreated || !canEdit"
          @click="modalRedCard(0)"
          class="btn-danger btn w-100"
        >
          Red Card
        </button>
        <button
          :disabled="!rosterCreated || !canEdit"
          @click="modalSubstitution(0)"
          class="btn-secondary btn w-100"
        >
          Substitution
        </button>
      </div>
      <div class="d-flex" style="height: inherit;">
        <div class="vr"></div>
      </div>
      <div class="w-100 px- d-flex flex-column gap-2">
        <button
          :disabled="!rosterCreated || !canEdit"
          @click="modalScoreGoal(1)"
          class="btn-success btn w-100"
        >
          Score
        </button>
        <button
          :disabled="!rosterCreated || !canEdit"
          @click="modalYellowCard(1)"
          class="btn-warning btn w-100"
        >
          Yellow Card
        </button>
        <button
          :disabled="!rosterCreated || !canEdit"
          @click="modalRedCard(1)"
          class="btn-danger btn w-100"
        >
          Red Card
        </button>
        <button
          :disabled="!rosterCreated || !canEdit"
          @click="modalSubstitution(1)"
          class="btn-secondary btn w-100"
        >
          Substitution
        </button>
      </div>
    </div>

    <div
      class="d-flex justify-content-center mt-3"
      v-if="events && events.length > 0 && events[0].event_type_id === 5"
    >
      <button class="btn btn-dark" @click="addSportMatchVAREvent">
        VAR - Goal Disallowed
      </button>
    </div>
    <Events :events="events" :match_id="match_id" :key="refresh" />
    <modalScoreGoal
      :team="eventTeam"
      :lineupHome="homeTeam.roster"
      :lineupAway="awayTeam.roster"
      :match_id="match_id"
      @response="score"
    ></modalScoreGoal>
    <modalYellowCard
      :team="eventTeam"
      :lineupHome="homeTeam.roster"
      :lineupAway="awayTeam.roster"
      :match_id="match_id"
      :events="events"
      @response="handleResponse"
    ></modalYellowCard>
    <modalRedCard
      :team="eventTeam"
      :lineupHome="homeTeam.roster"
      :lineupAway="awayTeam.roster"
      :match_id="match_id"
      @response="handleResponse"
    >
    </modalRedCard>
    <modalSubstitution
      :team="eventTeam"
      :lineupHome="homeTeam.roster"
      :homeSubs="homeSubs"
      :awaySubs="awaySubs"
      :lineupAway="awayTeam.roster"
      :match_id="match_id"
      @response="handleResponse"
    >
    </modalSubstitution>
    <modalMOTM
      :lineupHome="homeTeam.roster"
      :lineupAway="awayTeam.roster"
      :match_id="match_id"
      @response="handleResponse"
    >
    </modalMOTM>
    <modalEditRoster
      :match_id="match_id"
      :team="eventTeam"
      :lineupHome="homeTeam.roster"
      :lineupAway="awayTeam.roster"
      :homeSubs="homeSubs"
      :awaySubs="awaySubs"
      :team_id="eventTeam? awayTeam.away_team_id: homeTeam.home_team_id"
      :formation="eventTeam? awayTeam.away_formation: homeTeam.home_formation"
      @response="handleResponse"
    >
    </modalEditRoster>
    <modalChangeStatus
      :match_id="match_id"
      :match_status="match.match_status"
      :match_status_id="match.match_status_id"
      @response="statusUpdated"
    >
    </modalChangeStatus>
  </Layout>
</template>

<style>
p {
  margin: 0;
}
.logo-cover {
  width: 200px;
}
.loading {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  color: transparent;
  background-color: grey;
}
.bg-orange {
  background-color: #ffb07a !important;
  color: #fae1cf;
}
.bg-gray {
  background-color: #d3d3d3 !important;
  color: #6e6d6d;
}
.bg-gold {
  background-color: #ffd700;
  /* color: #FFFFFF; */
}
.bg-primary {
  background-color: #a7b0f0 !important;
  color: #5871ed;
}
.mr-2 {
  margin-right: 0.5rem;
}
</style>
